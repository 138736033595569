export default function useDebounce(cb, delay = 100) {
  let timer = null;

  return function() {
    clearTimeout(timer);
    let args = arguments,
      context = this;
    timer = setTimeout(() => {
      cb.apply(context, args);
    }, delay);
  };
}
