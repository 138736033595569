import { getCurrentInstance } from '@vue/composition-api';
export const useRoute = () => {
  const instance = getCurrentInstance().proxy;

  if (!instance) return null;

  // 代码目的：为了解决 keep-alive 缓存组件后，组件再次 active 后会造成两个 route 对象的问题
  // 异常表现：第二层 keep-alive 缓存对象的 route 会按照缓存时的值永久保存，不再更新
  // 解决方案：使用代理拦截，不去使用缓存的对象，而是每次都从 this.$route 中获取，这样就不会有问题了
  // 警告：不要轻易使用这个 Api
  const handleProxy = {
    get(_, name) {
      return instance.$route[name];
    }
  };
  return new Proxy({}, handleProxy);
};
export const useRouter = () => {
  const instance = getCurrentInstance().proxy;
  return instance ? instance.$router : null;
};
export const useStore = () => {
  const instance = getCurrentInstance().proxy;
  return instance ? instance.$store : null;
};
export const useRefs = () => {
  const instance = getCurrentInstance().proxy;
  return instance ? instance.$refs : null;
};

export const useUpdate = () => {
  const instance = getCurrentInstance().proxy;
  return instance ? instance.$forceUpdate : null;
};
