import { http } from '../utils/http';

const path = process.env.VUE_APP_PATH + '/driver-center';

// 查看资金流水明细
export const apiSearchAccountFundFlowList = async params => {
  return await http.post(`${path}/driver/mine/balance/searchAccountFundFlowList`, params, false);
};

// 查看更多流水明细
export const apiSearchAccountFundFlowMap = async params => {
  return await http.post(`${path}/driver/mine/balance/searchAccountFundFlowMap`, params, false);
};

// 查看更多流水明细
export const apiGetAccountFundFlow = async params => {
  return await http.post(`${path}/driver/mine/balance/getAccountFundFlow`, params, false);
};

// 提现记录
export const apiSearchTradeWithdrawRecordPage = async params => {
  return await http.post(`${path}/driver/mine/balance/searchTradeWithdrawRecordPage`, params, false);
};

// 查看余额
export const apiSearchBalance = async params => {
  return await http.post(`${path}/driver/mine/balance/searchBalance`, params, false);
};

// 提现
export const apiWithdraw = async params => {
  return await http.post(`${path}/driver/mine/balance/withdraw`, params, false);
};
