<template>
  <div class="balanceDetailInfo-content">
    <!-- 导航栏 -->
    <NavBar title="提现"> </NavBar>

    <div class="p-24">
      <!-- 选择银行 -->
      <p class="text-main text-14 mb-16">提现到</p>
      <div class="flex justify-between">
        <div class="flex">
          <img class="w-20 h-20" :src="getBankIconByCode(currentBank.bankCode)" />
          <div class="ml-16">
            <p class="text-16 text-main font-400">
              {{ currentBank.bankName }}（{{ currentBank.bankAccount.slice(-4) }}）
            </p>
            <span class="text-14 text-muted mt-4">预计1小时到账</span>
          </div>
        </div>

        <!-- <Icon style="margin-left:auto;" name="jt-h"></Icon> -->
        <span class="text-primary text-14" @click="show = true">更换银行卡</span>
      </div>

      <div class="mt-34">
        <div class="flex items-center">
          <span class="text-main text-14 mr-8">提现金额</span>
          <!-- <Icon name="icon_tips" @click.native="showNotePopup = true" /> -->
        </div>

        <div
          class="text-24 font-600 text-main flex items-center van-hairline--bottom mt-20"
          :class="{ error: limitErrMsg }"
        >
          <span class="mr-20 flex-shrink-0">¥</span>
          <van-field
            class="money-input"
            :readonly="onlyRead"
            v-model="money"
            type="number"
            @input="onInput"
            center
            clearable
            placeholder="请输入提现金额"
          >
          </van-field>
        </div>

        <div class="text-danger text-14 py-8" v-if="limitErrMsg">{{ limitErrMsg }}</div>

        <div class="text-14 mt-8">
          <span class="text-muted">可提现余额：{{ useFormatMoney(balance / 100, 2, '') }}元</span>
          <!-- <span class="text-primary ml-8" @click="onWithdrawAll">全部提现</span> -->
        </div>
      </div>

      <!-- <p class="text_secondary bdt pt-16">可提现金额”</p>
      <p class="lh-48 font-24 mt-16 fw-600">￥ {{ useFormatMoney(balance / 100, 2, '') }}</p>
      <p class="text_muted font-12 pt-10 bdt">预计 <span class="text_primary">1小时</span> 到账</p> -->
    </div>

    <div class="tips">
      <p class="tips-title text-muted">
        <Icon name="icon_common_toast_warning_yellow" id="icon_common_toast_warning_yellow"></Icon>提现须知
      </p>
      <p class="tips-item text-muted">①可提现金额低于1万时，默认全额提现</p>
      <p class="tips-item text-muted">②单笔提现不能大于等于100万</p>
      <p class="tips-item text-muted">③预计1小时到账</p>
    </div>

    <!-- 提现按钮 -->
    <div class="btn-box">
      <Button size="large" :disabled="money <= 0" @click="handleWithdraw" type="primary">提现</Button>
    </div>

    <!-- 银行列表 -->
    <Popup class="pa-16" v-model="show" round position="bottom">
      <div class="popup-box">
        <p class="text_primary font-16" @click="show = false">取消</p>
        <ul class="bank-list mt-16">
          <li
            class="d-flex align-center lh-40 mb-8 fon-14"
            v-for="item in userBankList"
            :key="item.payeeId"
            @click="handleChooseBank(item.payeeId)"
          >
            <img :src="getBankIconByCode(item.bankCode)" />
            <div class="d-flex justify-space-between ml-8 bdb" style="flex:1;">
              <p>{{ item.bankName }}（{{ item.bankAccount.slice(-4) }}）</p>
              <Icon :name="item.checked ? 'xz' : 'weixuan'"></Icon>
            </div>
          </li>
        </ul>
      </div>
    </Popup>

    <Popup v-model="showNotePopup" round position="bottom">
      <div class="flex flex-col items-center p-24">
        <span class="text-20 font-500 text-main">提现说明</span>
        <p class="text-muted text-16 mt-24">可提现金额低于5万，默认全额提现</p>
        <p class="text-muted text-16 mt-14 mb-24">单笔提现不能大于等于100万</p>
        <span class="text-primary text-16 font-500" @click="showNotePopup = false">我知道了</span>
      </div>
    </Popup>
  </div>
</template>
<script>
import { computed, defineComponent, onMounted, ref } from '@vue/composition-api';
import { useRouter } from '@/utils/compsitionHack';
import NavBar from '@/components/NavBar/index';
import { Popup, Button, Toast } from 'vant';
import useFormatMoney from '@/hook/useFormatMoney';
import { getBankList } from '@/api/mine';
import { apiSearchBalance, apiWithdraw } from '@/api/balance';
import { bankList, getBankIconByCode } from '../../mine/constants';
import useDebounce from '@/hook/useDebounce';
import { useUserStore } from '@/store/user';
import { PageEnum } from '@/enums/pageEnum';
import { cent2yuan, yuan2cent } from '@/utils/unit';

const MoneyLimit = {
  min: 10000, // 1万
  max: 1000000 // 100万
};

export default defineComponent({
  components: {
    NavBar,
    Popup,
    Button
  },
  methods: {
    // onBlur() {
    //   const realBalance = cent2yuan(this.balance);
    //   this.money = this.money < realBalance ? realBalance : Math.min(999999.99, +this.money, +realBalance);
    // },
    onInput(val) {
      this.money = val?.match(/^\d*(\.?\d{0,2})/g)[0];
      if (this.inputReadonly) return;
      const realBalance = cent2yuan(this.balance);
      if (this.money < MoneyLimit.min) {
        this.limitErrMsg = '提现金额不能小于1万元';
      } else if (this.money > MoneyLimit.max) {
        this.limitErrMsg = '单笔提现金额不大于100万元';
      } else if (this.money > realBalance) {
        this.limitErrMsg = '提现金额不能大于可提现金额';
      } else {
        this.limitErrMsg = '';
      }
    }
  },
  setup() {
    const router = useRouter();
    const userStore = useUserStore();
    // 改变页面背景颜色
    onMounted(async () => {
      const idCard = userStore?.idCard;

      // 获取银行卡列表
      try {
        const res = await getBankList();
        if (res) {
          res.data.forEach(item => {
            if (item.defaultState === 1) {
              item.checked = true;
              currentBank.value = item;
            } else {
              item.checked = false;
            }
          });
          userBankList.value = res.data;
          payeeId.value = userBankList.value[0].payeeId; // 默认第一张银行卡
        }
        const response = await apiSearchBalance({ driverIdCard: idCard });
        if (response.data) {
          balance.value = response.data;
          // onWithdrawAll();
          const maxCent = yuan2cent(MoneyLimit.max);
          if (maxCent <= balance.value) {
            money.value = cent2yuan(maxCent - 1);
          } else {
            money.value = cent2yuan(balance.value);
          }
          onlyRead.value = yuan2cent(MoneyLimit.min) >= balance.value;
        }
      } catch (err) {
        console.log(err);
      }
    });

    const show = ref(false);

    const userBankList = ref([]);
    const currentBank = ref({});

    const payeeId = ref('');
    const balance = ref('');
    const money = ref('');
    const limitErrMsg = ref('');

    const showNotePopup = ref(false);

    const handleChooseBank = id => {
      payeeId.value = id;
      show.value = false;

      userBankList.value.forEach(item => {
        if (item.payeeId === id) {
          item.checked = true;
          currentBank.value = item;
        } else {
          item.checked = false;
        }
      });
    };

    function inputCheck() {
      const realBalance = cent2yuan(balance.value);
      if (realBalance <= MoneyLimit.min) {
        return true;
      }
      if (money.value < MoneyLimit.min) {
        Toast('提现金额不能小于1万元');
      } else if (money.value >= MoneyLimit.max) {
        Toast('单笔提现金额不能大于等于100万元');
      } else if (money.value > realBalance) {
        Toast('提现金额不能大于可提现金额');
      } else {
        return true;
      }
      return false;
    }

    const handleWithdraw = useDebounce(async () => {
      if (inputCheck()) {
        try {
          const res = await apiWithdraw({
            amount: yuan2cent(money.value),
            bankPayeeId: payeeId.value
          });
          if (res.data) {
            Toast('提现成功');
            router.push(PageEnum.BALANCE_RECORDS);
          }
        } catch (err) {
          console.log(err);
        }
      }
    }, 500);

    const inputReadonly = computed(() => {
      if (cent2yuan(balance.value) < MoneyLimit.min) {
        return true;
      }
      return false;
    });

    const onWithdrawAll = () => {
      const totalMoney = cent2yuan(balance.value);
      if (totalMoney <= 0) return;
      if (totalMoney >= MoneyLimit.max) {
        // 大于等于100w 置成99w
        money.value = 999999.99;
      } else {
        money.value = totalMoney;
      }
    };

    const onlyRead = ref(true);

    return {
      useFormatMoney,
      show,
      currentBank,
      userBankList,
      bankList,
      payeeId,
      balance,
      handleChooseBank,
      handleWithdraw,

      showNotePopup,
      // onInput,
      // inputFormatter,
      inputReadonly,
      money,
      limitErrMsg,
      onWithdrawAll,
      onlyRead,
      inputCheck,
      getBankIconByCode
    };
  }
});
</script>
<style lang="less" scoped>
.balanceDetailInfo-content {
  .money-input {
    padding: 0.08rem 0;
    /deep/ input {
      height: 0.34rem;
      font-size: 0.24rem !important;

      &::placeholder {
        font-weight: normal;
        color: #d4d4d4;
      }
    }
  }

  .van-hairline--bottom.error {
    &::after {
      border-color: #e72832;
    }
  }

  .content {
    padding: 0.16rem;
    background: #fff;
    height: calc(100vh - 1.32rem);
    .bank {
      height: 0.56rem;
      .bank-icon {
        height: 0.24rem;
        width: 0.24rem;
      }
    }
  }
}
.popup-box {
  .bank-list {
    max-height: 2.5rem;
    overflow: auto;
    img {
      height: 0.16rem;
      width: 0.16rem;
    }
  }
}

.tips {
  margin-left: 0.12rem;
  margin-right: 0.12rem;
  background: #fffbe8;
  border-color: #f4e6bd;
  border-radius: 0.04rem;
  border-width: 0.01rem;
  border-style: solid;
  padding: 0.1rem;
}
.tips-title {
  padding-bottom: 0.1rem;
}
.tips-item {
  padding-left: 0.18rem;
  padding-bottom: 0.05rem;
}
#icon_common_toast_warning_yellow {
  margin-right: 0.05rem;
}
</style>
<style lang="less"></style>
