// import { omitBy } from 'lodash-es';
import BigNumber from 'bignumber.js';

// 几乎永远不会返回指数形式，但是为了安全，为了确保精度，一律采用string传值
BigNumber.config({ EXPONENTIAL_AT: 1e9 });

// 重量单位
const WeightUnitMap = {
  Ton: 1,
  Kg: 2
};

// 单价单位
const UnitPriceTypeMap = {
  Weight: 1,
  Volume: 2,
  Number: 3
};

/**
 * 判断是否是空值
 */
export function isEmptyVal(val) {
  return val === '' || val === undefined || val === null;
}

/**
 * 去掉空值对象
 * @param {*} obj
 * @returns obj
 */
// export function compactObj(obj) {
//   return omitBy(obj, v => isEmptyVal(v));
// }

/**
 * 元转分
 * @param {string | number} val
 * @returns
 */
export function yuan2cent(val) {
  const number = new BigNumber(val).times(100).toNumber();
  if (isNaN(number) || isEmptyVal(number)) return '';
  return number;
}

/**
 * 分转元
 * @param {string | number} val
 * @returns
 */
export function cent2yuan(val) {
  const number = new BigNumber(val).div(100).toNumber();
  if (isNaN(number) || isEmptyVal(number)) return '';
  return number;
}

/**
 * 吨/千克 转换成 克
 * @param {*} val
 * @param {*} weightUnit 重量单位 1:吨 2:千克
 * @returns
 */
export function weight2gram(val, weightUnit) {
  const bigNumber = new BigNumber(val);
  if (isNaN(bigNumber.toNumber()) || isEmptyVal(bigNumber.toNumber())) return '';
  if (weightUnit === WeightUnitMap.Kg) {
    return bigNumber.times(1000).toNumber();
  }
  return bigNumber.times(1000000).toNumber();
}

/**
 * 克 转换成 吨/千克
 * @param {*} val
 * @param {*} weightUnit 重量单位, 不传入默认取全局的单位配置信息 1:吨 2:千克
 * @returns
 */
export function gram2weight(val, weightUnit) {
  const bigNumber = new BigNumber(val);
  if (isNaN(bigNumber.toNumber()) || isEmptyVal(bigNumber.toNumber())) return '';
  if (weightUnit === WeightUnitMap.Kg) {
    return parseFloat(bigNumber.div(1000).toFixed(3));
  }
  return parseFloat(bigNumber.div(1000000).toFixed(3));
}

/**
 * 立方米 转成 立方分米
 * @param {*} val
 * @returns
 */
export function volume2dm(val) {
  const bigNumber = new BigNumber(val);
  if (isNaN(bigNumber.toNumber()) || isEmptyVal(bigNumber.toNumber())) return '';
  return bigNumber.times(1000).toNumber();
}

/**
 * 立方分米 转成 立方米
 * @param {*} val
 * @returns
 */
export function dm2volume(val) {
  const bigNumber = new BigNumber(val);
  if (isNaN(bigNumber.toNumber()) || isEmptyVal(bigNumber.toNumber())) return '';
  return parseFloat(bigNumber.div(1000).toFixed(3));
}
// ==================================

/**
 * 单价转换 元/吨 元/千克 元/方 元/件
 * 元/吨   -> 分/吨   unitPriceType: 1
 * 元/千克 -> 分/吨   unitPriceType: 1
 * 元/方   -> 分/方   unitPriceType: 2
 * 元/件   -> 分/件   unitPriceType: 3
 * @param {*} number 数字
 * @param {*} unitPriceType 1:元/吨 元/千克 2:元/方 3:件
 * @returns
 */
export function unitPrice2cent(val, unitPriceType, weightUnit) {
  const bigNumber = new BigNumber(val);
  if (isNaN(bigNumber.toNumber()) || isEmptyVal(val)) return '';
  if (unitPriceType === UnitPriceTypeMap.Weight) {
    if (weightUnit === WeightUnitMap.Kg) {
      // 元/kg -> 分/吨
      return bigNumber.times(100000).toNumber();
    }
    // 元/吨 -> 分/吨
    return bigNumber.times(100).toNumber();
  }
  if (unitPriceType === UnitPriceTypeMap.Volume || unitPriceType === UnitPriceTypeMap.Number) {
    // 元/方 -> 分/方 元/件 -> 分/件
    return bigNumber.times(100).toNumber();
  }
}

/**
 * 单价转换 （计价规则需要用）
 * 分/吨  ->  元/吨    unitPriceType: 1
 * 分/吨  ->  元/千克  unitPriceType: 1
 * 分/方  ->  元/方    unitPriceType: 2
 * 分/件  ->  元/件    unitPriceType: 3
 * @param {*} val
 * @param {*} unitPriceType  1:元/吨 元/千克 2:元/方 3:件
 * @param {*} weightUnit 重量单位
 * @returns
 */
export function cent2UnitPrice(val, unitPriceType, weightUnit) {
  const bigNumber = new BigNumber(val);
  if (isNaN(bigNumber.toNumber()) || isEmptyVal(bigNumber.toNumber())) return '';
  if (unitPriceType === UnitPriceTypeMap.Weight) {
    if (weightUnit === WeightUnitMap.Kg) {
      // 分/吨 -> 元/千克
      return bigNumber.div(100000).toNumber();
    }
    // 分/吨 -> 元/吨
    return bigNumber.div(100).toNumber();
  }
  if (unitPriceType === UnitPriceTypeMap.Volume || unitPriceType === UnitPriceTypeMap.Number) {
    // 分/方 => 元/方 分/件 -> 元/件
    return bigNumber.div(100).toNumber();
  }
}

/**
 * 亏损数量转换 前端 -> 后端
 * @param {*} val
 * @param {*} unitPriceType 运输单价单位 1:[元/吨 | 元/千克] 2:[元/方] 3:[元/件]
 * @param {*} lossUnitType 允许亏损的数量单位 0:[吨 | 千克 | 方 | 件] 1:[千分号]
 * @param {*} weightUnit 重量单位 1:吨 2:千克
 * @returns
 */
export function lossNumber2backend(val, unitPriceType, lossUnitType, weightUnit) {
  const bigNumber = new BigNumber(val);
  if (isNaN(bigNumber.toNumber()) || isEmptyVal(bigNumber.toNumber())) return '';
  if (lossUnitType === 0) {
    if (unitPriceType === UnitPriceTypeMap.Weight) {
      if (weightUnit === WeightUnitMap.Kg) {
        // kg => g
        return bigNumber.times(1000).toNumber();
      }
      // 吨 => g
      return bigNumber.times(1000000).toNumber();
    }
    // 方 => 立方分米
    if (unitPriceType === UnitPriceTypeMap.Volume) {
      return bigNumber.times(1000).toNumber();
    }
    // 件 => 件
    if (unitPriceType === UnitPriceTypeMap.Number) {
      return bigNumber.toNumber();
    }
  }
  // 千分号 不转换
  return bigNumber.toNumber();
}

/**
 * 亏损数量转换 后端 -> 前端
 * @param {*} val
 * @param {*} unitPriceType 运输单价单位 1:[元/吨 | 元/千克] 2:[元/方] 3:[元/件]
 * @param {*} lossType 允许亏损的数量单位 0:[吨 | 千克 | 方 | 件] 1:[千分号]
 * @param {*} weightUnit 重量单位 1:吨 2:千克
 * @returns
 */
export function backend2lossNumber(val, unitPriceType, lossUnitType, weightUnit) {
  const bigNumber = new BigNumber(val);
  if (isNaN(bigNumber.toNumber()) || isEmptyVal(bigNumber.toNumber())) return '';

  if (lossUnitType === 0) {
    // 吨/kg
    if (unitPriceType === UnitPriceTypeMap.Weight) {
      if (weightUnit === WeightUnitMap.Kg) {
        // g => kg
        return bigNumber.div(1000).toNumber();
      }
      // g => 吨
      return bigNumber.div(1000000).toNumber();
    }
    // 立方分米 => 方
    if (unitPriceType === UnitPriceTypeMap.Volume) {
      return bigNumber.div(1000).toNumber();
    }
    // 件 => 件
    if (unitPriceType === UnitPriceTypeMap.Number) {
      return bigNumber.toNumber();
    }
  }
  return bigNumber.toNumber();
}
